import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import * as rehypeReact from 'rehype-react'

const BlogPost = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, htmlAst } = markdownRemark
  return (
    <Fragment className="blog-post-container">
      <Helmet title={frontmatter.title}/>
      <article className="blog-post">
        <header>
          <h1>{frontmatter.title}</h1>
        </header>
        <h2>{frontmatter.date}</h2>
        <div className="blog-post-content">
          {
            renderAst(htmlAst)
          }
        </div>
      </article>
    </Fragment>
  )
}

const renderAst = new rehypeReact({
  createElement: React.createElement
}).Compiler

BlogPost.propTypes = {
  data: PropTypes.object.isRequired
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
